import { createRouter, createWebHashHistory } from 'vue-router';
import { getItem, setItem } from '../assets/js/storage.js';
import routes from './model';
import { tool } from '@/stores/tool';
import mapMenu from '@/components/main/menu.js';
import { checkObj } from '@/utils';

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

var list = [];
mapMenu.SW.forEach(e => {
  list.push(e);
  if (e.children && e.children.length) {
    list = [...list, ...e.children];
  }
})
mapMenu.HW.forEach(e => {
  list.push(e);
  if (e.children && e.children.length) {
    list = [...list, ...e.children];
  }
})

router.beforeEach(async (to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  let wasteType = tool().wasteType;
  let sideBar = tool().sideBar;
  let sideView = tool().sideView;
  var token = getItem('token');
  if (token) {
    if (to.path == '/login') {//token存在，并且是login页面
      if (Object.keys(sideBar).length == 1 && sideBar.SW && sideBar.SW.length) {
        const sideBarNav = getItem('sideBar').SW[0];
        let obj = findPath(sideBarNav)
        if (obj.path == '/factory/index') {
          next();
        } else {
          next(obj.path);
        }
      } else {
        const sideBarNav = getItem('sideBar').HW[0];
        let obj = findPath(sideBarNav)
        if (obj.path == '/hazardous/index') {
          next();
        } else {
          next(obj.path);
        }
      }
    } else {//token存在，不是login页面
      // console.log(from);
      // console.log(to);
      // if(to.path == '/factory/index'){
      //   const index = list.findIndex(o => o.permission == to.meta.permission);
      //   // console.log(index);
      //   if(index >= 0){
      //     setItem('wasteType', 'SW');
      //     next()
      //   }else{
      //     if(Object.keys(sideBar).length == 1 && sideBar.HW && sideBar.HW.length){
      //       setItem('wasteType', 'HW');
      //       next('/hazardous/index');
      //     }else{
      //       setItem('wasteType', 'SW');
      //       const sideBarNav = getItem('sideBar').SW[0];
      //       let permission = '';
      //       if(sideBarNav.child && sideBarNav.child.length){
      //         permission = sideBarNav.child[0].permission;
      //       }else{
      //         permission = sideBarNav.permission;
      //       }
      //       let obj = list.find(o => o.permission == permission);
      //       next(obj.path);
      //     }
      //   }
      // }else{
      //   next();
      // }

      if (to.path == '/hazardous/index') {
        setItem('wasteType', 'HW');
        const sideBarNav = getItem('sideBar').HW[0];
        let obj = findPath(sideBarNav)
        if (obj.path == '/hazardous/index') {
          next();
        } else {
          next(obj.path);
        }
      } else if (to.path == '/factory/index') {
        setItem('wasteType', 'SW');
        const sideBarNav = getItem('sideBar').SW[0];
        let obj = findPath(sideBarNav)
        if (obj.path == '/factory/index') {
          next();
        } else {
          next(obj.path);
        }
      } else {
        next();
      }
    }
  } else {
    if (to.path == '/login') {//token不存在，并且是login页面
      next();
    } else {//token不存在，不是login页面
      next('/login')
    }
  }
})

function findPath(sideBarNav) {
  let permission = '';
  if (sideBarNav.child && sideBarNav.child.length) {
    permission = sideBarNav.child[0].permission;
  } else {
    permission = sideBarNav.permission;
  }
  let obj = list.find(o => o.permission == permission);
  return obj
}

export default router
